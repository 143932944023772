import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './WhyChooseUs.css';

const WhyChooseUs: React.FC = () => {
  return (
    <section className="why-choose-us py-5">
      <Container>
        <h2 className="text-center mb-4">Why Choose Us?</h2>
        <Row className="text-center">
          <Col md={3} className="mb-4">
            <i className="bi bi-cash-stack mb-2 why-icon"></i>
            <h5>Affordable Pricing</h5>
            <p>We offer competitive pricing for high-quality services.</p>
          </Col>
          <Col md={3} className="mb-4">
            <i className="bi bi-palette mb-2 why-icon"></i>
            <h5>Custom Branding</h5>
            <p>Your website will be tailored to match your brand's unique identity.</p>
          </Col>
          <Col md={3} className="mb-4">
            <i className="bi bi-phone mb-2 why-icon"></i>
            <h5>Mobile-Friendly Design</h5>
            <p>We ensure that your website looks great on all devices.</p>
          </Col>
          <Col md={3} className="mb-4">
            <i className="bi bi-clock mb-2 why-icon"></i>
            <h5>Quick Turnaround</h5>
            <p>Get your website ready in just 1-2 weeks.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyChooseUs;
