import React from 'react';
import { Container, Row } from 'react-bootstrap';
import './ServiceCategory.css';

interface ServiceCategoryProps {
  title: string;
  children: React.ReactNode;
}

const ServiceCategory: React.FC<ServiceCategoryProps> = ({ title, children }) => {
  return (
    <section className="service-category my-5">
      <Container>
        <h3 className="category-title text-center mb-4">{title}</h3>
        <Row>{children}</Row>
      </Container>
    </section>
  );
};

export default ServiceCategory;
