import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './DetailedServiceCard.css';

interface DetailedServiceCardProps {
  title: string;
  description: string;
  price: string;
  features: string[];
  link: string;
  buttonText: string; // Ensure this is listed here as required
}

const DetailedServiceCard: React.FC<DetailedServiceCardProps> = ({ 
  title, 
  description, 
  price, 
  features, 
  link, 
  buttonText 
}) => {
  return (
    <Card className="detailed-service-card">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <p className="price">{price}</p>
        <ul className="features-list">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <Button variant="warning" href={link}>
          {buttonText}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default DetailedServiceCard;
