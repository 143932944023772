import React from 'react';
import ServiceCategory from '../components/ServiceCategory';
import DetailedServiceCard from '../cards/DetailedServiceCard';

const ServicesPage: React.FC = () => {
  return (
    <main>
      <section className="services-page" aria-label="Our Services">
        <h2 className="text-center mb-5">Our Services</h2>

        <ServiceCategory title="Website Development Services">
          <DetailedServiceCard
            title="Basic Website Package"
            description="Affordable website design for small businesses, including mobile-friendly and SEO-optimized websites to enhance your online presence."
            price="Contact for a tailored quote"
            features={[
              'Up to 5 pages (e.g., Home, About, Services, Contact)',
              'Custom design tailored to your brand',
              'Mobile-friendly and responsive design',
              'Integration with social media',
              'Basic SEO setup for better visibility',
            ]}
            link="/contact"
            buttonText="Get a Free Website Quote"
          />
          {/* Additional website packages go here */}
        </ServiceCategory>

        <ServiceCategory title="Domain Registration & Hosting">
          <DetailedServiceCard
            title="Domain Registration & Hosting"
            description="Ensure your website stays secure and accessible with our managed hosting services, ideal for businesses in Manchester, TN, and beyond."
            price="Contact for a tailored quote"
            features={[
              'Domain registration and renewal management',
              'Managed hosting with SSL certificate',
              'Server monitoring and regular backups',
              'Up to 4 custom email addresses',
            ]}
            link="/contact"
            buttonText="Learn More About Hosting"
          />
          {/* Additional hosting services go here */}
        </ServiceCategory>
      </section>
    </main>
  );
};

export default ServicesPage;
