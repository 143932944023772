import React from 'react';
import './Footer.css';
import logo from '../assets/logo.png';

interface FooterProps {
  year: number; // So you can dynamically set the year
}

const Footer: React.FC<FooterProps> = ({ year }) => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-copyright-section">
          <p>&copy; {year} NexStep Web Devlopment. All rights reserved.</p>
        </div>
        <div className="footer-logo-section">
          <a
            href="https://nexstepweb.com"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            Powered by{' '}
            <img src={logo} alt="NexStep Web" className="footer-logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
