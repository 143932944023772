import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaCheckCircle, FaHandshake, FaClock } from 'react-icons/fa';
import ValueCard from '../cards/ValueCard';
import './ValuesAndApproach.css';

const ValuesAndApproach: React.FC = () => {
  return (
    <section className="values-and-approach py-5">
      <Container>
        <h2 className="text-center mb-4">Our Values & Approach</h2>
        <Row className="mb-5">
          <Col md={4} className="mb-4">
            <ValueCard
              icon={<FaCheckCircle />}
              title="Quality"
              description="We are committed to delivering high-quality websites that meet modern standards and exceed client expectations."
            />
          </Col>
          <Col md={4} className="mb-4">
            <ValueCard
              icon={<FaHandshake />}
              title="Transparency"
              description="We believe in open and honest communication, ensuring our clients are informed every step of the way."
            />
          </Col>
          <Col md={4} className="mb-4">
            <ValueCard
              icon={<FaClock />}
              title="Customer-Centric Approach"
              description="Your satisfaction is our priority. We focus on your needs and tailor each solution to fit your unique business goals."
            />
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col className="text-center">
            <p className="approach-text">
              Our approach to website development emphasizes customization, collaboration, and speed. We work closely with clients to understand their vision, offering tailored solutions that align with their brand. With a focus on fast turnaround times, we ensure that your website is up and running quickly, without compromising quality.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ValuesAndApproach;
