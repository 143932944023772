import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './PortfolioPage.css';

const portfolioItems = [
  {
    title: 'Sample Project 1',
    description: 'Example of a homepage design for small businesses',
    imageSrc: '/assets/placeholder1.jpg',
    altText: 'Homepage Design Example for Small Business Website',
  },
  {
    title: 'Sample Project 2',
    description: 'Screenshot of a responsive template design',
    imageSrc: '/assets/placeholder2.jpg',
    altText: 'Responsive Template Design Screenshot',
  },
  {
    title: 'Sample Project 3',
    description: 'Concept for an e-commerce page with product filtering',
    imageSrc: '/assets/placeholder3.jpg',
    altText: 'E-commerce Page Concept with Product Filtering',
  },
];

const PortfolioPage: React.FC = () => {
  return (
    <main>
      <section className="portfolio-page py-5" aria-label="Portfolio">
        <Container className="position-relative">
          <h2 className="text-center mb-4">Website Design Portfolio – Custom Website Examples</h2>
          <Row>
            {portfolioItems.map((item, index) => (
              <Col md={4} className="mb-4" key={index}>
                <Card className="portfolio-card">
                  <Card.Img variant="top" src={item.imageSrc} alt={item.altText} loading="lazy" />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <div className="overlay">
            <h1 className="coming-soon">Coming Soon!</h1>
            <p className="text-center mt-4">Interested in seeing more? <a href="/contact">Contact us</a> to discuss your website needs today!</p>
          </div>
        </Container>
      </section>
    </main>
  );
};

export default PortfolioPage;
