import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CallToAction.css';

const CallToAction: React.FC = () => {
  return (
    <section className="call-to-action py-5">
      <Container>
        <Row className="align-items-center text-center text-md-start">
          <Col md={8}>
            <h2 className="cta-title">
              Ready to elevate your online presence?
            </h2>
            <p className="cta-subtitle">
              Contact us today to build a professional, mobile-friendly website
              that meets your business needs.
            </p>
          </Col>
          <Col md={4} className="text-md-end mt-3 mt-md-0">
            <Link to="/contact">
              <Button variant="warning" className="cta-button">
                Contact Us Today
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CallToAction;
