import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './ProfessionalBackground.css';

const ProfessionalBackground: React.FC = () => {
  return (
    <section className="professional-background py-5">
      <Container>
        <h2 className="text-center mb-4">Professional Background</h2>
        <Row className="mb-5">
          <Col md={6}>
            <h5 className="skills-title">Skills & Expertise</h5>
            <ul className="skills-list">
              <li>HTML & CSS</li>
              <li>JavaScript & TypeScript</li>
              <li>React & React Native</li>
              <li>Vue & Angular</li>
              <li>Bootstrap & Tailwind</li>
              <li>Java (Spring Boot, Spring Security, Hibernate)</li>
              <li>Responsive Web Design & Mobile Optimization</li>
              <li>Search Engine Optimization (SEO)</li>
              <li>API Integration & Development</li>
              <li>PostgreSQL & Database Management</li>
            </ul>
          </Col>
          <Col md={6}>
            <h5 className="certifications-title">Certifications & Training</h5>
            <Row>
              <Col xs={12} className="mb-3">
                <Card className="certification-card">
                  <Card.Body>
                    <Card.Title>Tech Elevator Full-Stack Developer Certification</Card.Title>
                    <Card.Text>
                      A comprehensive full-stack development certification covering Java, Spring, JavaScript, and modern front-end frameworks.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} className="mb-3">
                <Card className="certification-card">
                  <Card.Body>
                    <Card.Title>Cook System's FastTrack Full-Stack Developer Certification</Card.Title>
                    <Card.Text>
                      Intensive training program focusing on full-stack web development using Java, Spring Boot, and modern JavaScript frameworks.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProfessionalBackground;
