import React from 'react';
import Introduction from '../components/Introduction';
import ValuesAndApproach from '../components/ValuesAndApproach';
import ProfessionalBackground from '../components/ProfessionalBackground';

const AboutPage: React.FC = () => {
  return (
    <main className="about-page">
      <section aria-label="Introduction">
        <Introduction />
      </section>
      <section aria-label="Values and Approach">
        <ValuesAndApproach />
      </section>
      <section aria-label="Professional Background">
        <ProfessionalBackground />
      </section>
    </main>
  );
};

export default AboutPage;
