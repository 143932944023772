import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Introduction.css';

const Introduction: React.FC = () => {
  return (
    <section className="introduction py-5">
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="text-center text-md-start">
            <h2 className="section-title">Welcome to NexStep Web Development</h2>
            <p className="intro-text">
              Hi, I'm Devin, the founder of NexStep Web Development. My passion for web development began with a love for technology and a drive to help small businesses thrive in the digital world. Over the years, I've honed my skills in creating affordable, mobile-friendly websites tailored to the unique needs of each client.
            </p>
            <p className="intro-text">
              With a focus on providing accessible and high-quality web solutions, I am dedicated to helping small businesses establish a strong online presence, ensuring they can reach their audience wherever they are.
            </p>
          </Col>
          <Col md={6} className="text-center">
            <img src="/my-headshot.jpg" alt="Your Professional Headshot" className="intro-image" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Introduction;
