import React from 'react';
import HeroSection from '../components/HeroSection';
import KeyServices from '../components/KeyServices';
import WhyChooseUs from '../components/WhyChooseUs';
import Testimonials from '../components/Testimonials';
import CallToAction from '../components/CallToAction';

const HomePage: React.FC = () => {
  return (
    <main>
      <header>
        <HeroSection />
      </header>
      <section aria-label="Key Services">
        <KeyServices />
      </section>
      <section aria-label="Why Choose Us">
        <WhyChooseUs />
      </section>
      {/* Uncomment when ready */}
      {/* <section aria-label="Testimonials">
        <Testimonials />
      </section> */}
      <section aria-label="Call to Action">
        <CallToAction />
      </section>
    </main>
  );
};

export default HomePage;
