import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './HeroSection.css';

const HeroSection: React.FC = () => {
  return (
    <section className="hero-section d-flex align-items-center text-center text-light">
      <Container>
        <h1 className="hero-heading">
          Empowering Small Businesses with Professional, Mobile-Friendly
          Websites
        </h1>
        <Link to="/contact">
          <Button variant="warning" className="mt-4 hero-btn">
            Get Started
          </Button>
        </Link>
      </Container>
    </section>
  );
};

export default HeroSection;
