import React from 'react';
import { Container } from 'react-bootstrap';
import ContactSection from '../components/ContactSection';
import './ContactPage.css';

const ContactPage: React.FC = () => {
  return (
    <main>
      <section className="contact-page py-5" aria-label="Contact Form">
        <Container>
          <ContactSection id="contact" />
          <div className="contact-info mt-5" aria-label="Direct Contact Information">
            <h3 className="text-center">Get in Touch Directly</h3>
            <p className="text-center">
              Email: <a href="mailto:devin.carter@nexstepweb.com">devin.carter@nexstepweb.com</a>
            </p>
            <p className="text-center">
              Phone: <a href="tel:+16153884562">+1 (615) 388-4562</a>
            </p>
            <p className="text-center">
              Serving small businesses in Manchester, Tennessee, and across the U.S. Contact us for local and remote website development services.
            </p>
          </div>
        </Container>
      </section>
    </main>
  );
};

export default ContactPage;
