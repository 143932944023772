import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ServiceCard from '../cards/ServiceCard';
import './KeyServices.css';

const KeyServices: React.FC = () => {
  return (
    <section className="key-services py-5">
      <Container>
        <h2 className="text-center mb-4">Our Key Services</h2>
        <Row className="text-center">
          <Col md={6} className="d-flex mb-4">
            <ServiceCard
              icon="bi bi-laptop"
              title="Basic Website Package"
              description="Custom, mobile-friendly websites designed to elevate your brand's online presence."
              link="/services#basic-website"
            />
          </Col>
          <Col md={6} className="d-flex mb-4">
            <ServiceCard
              icon="bi bi-server"
              title="Domain Registration & Hosting"
              description="Reliable domain registration and hosting services to keep your website online, all the time."
              link="/services#domain-hosting"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default KeyServices;
