import React from 'react';
import './SocialMediaSection.css';

interface SocialMediaSectionProps {
  id: string;
}

const SocialMediaSection: React.FC<SocialMediaSectionProps> = ({ id }) => {
  return (
    <section className="social-media-section" id={id}>
      <h3 className="social-media-title">CONNECT WITH US</h3>
      <div className="social-media-container">
        <a href="https://www.facebook.com/NexStepWebDev" target="_blank" rel="noreferrer">
          <i className="bi bi-facebook"></i>
        </a>
        <a href="https://x.com/NexStepWebDev" target="_blank" rel="noreferrer">
          <i className="bi bi-twitter-x"></i>
        </a>
        <a href="https://www.linkedin.com/company/105410223/admin/dashboard/" target="_blank" rel="noreferrer">
          <i className="bi bi-linkedin"></i>
        </a>
        {/*<a href="https://instagram.com" target="_blank" rel="noreferrer">
          <i className="bi bi-instagram"></i>
        </a>*/}
        <a href="https://youtube.com/channel/UCaW49_wAa4X1Smf9y9GYLGA" target="_blank" rel="noreferrer">
          <i className="bi bi-youtube"></i>
        </a>
        <a href="https://g.page/r/CacUO4Kmc08AEBM" target="_blank" rel="noreferrer">
          <i className="bi bi-google"></i>
        </a>
        <a href="https://www.pinterest.com/NexStepWebDev" target="_blank" rel="noreferrer">
          <i className="bi bi-pinterest"></i>
        </a>
      </div>
    </section>
  );
};

export default SocialMediaSection;
