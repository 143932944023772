import React from 'react';
import { Card } from 'react-bootstrap';
import './ValueCard.css';

interface ValueCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const ValueCard: React.FC<ValueCardProps> = ({ icon, title, description }) => {
  return (
    <Card className="value-card text-center">
      <div className="value-icon">{icon}</div>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ValueCard;
