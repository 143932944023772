import React, { useState } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css'; // Optional: for custom styles
import logo from '../assets/logo.png';
import brandName from '../assets/brandName.png';

const Header: React.FC = () => {
  // State to control the expanded state of the Navbar
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setExpanded(false);
  };

  return (
    <Navbar expand="lg" bg="dark" variant="dark" sticky="top" expanded={expanded}>
      <Container>
        {/* Logo/Brand Name */}
        <Navbar.Brand as={Link} to="/" className="brand-logo" onClick={handleClose}>
          <img
            src={logo} 
            alt="NexStep Logo"
            className="logo-img"
            style={{ width: '40px', height: 'auto', marginRight: '10px' }}
          />
          <img
            src={brandName}
            alt="NexStep Web Development"
            className="brand-name-img"
            style={{ width: '120px', height: 'auto' }}
          />
        </Navbar.Brand>

        {/* Mobile Hamburger Menu Toggle */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />

        {/* Navigation Links */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" onClick={handleClose}>
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about" onClick={handleClose}>
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/services" onClick={handleClose}>
              Services
            </Nav.Link>
            <Nav.Link as={Link} to="/portfolio" onClick={handleClose}>
              Portfolio
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" onClick={handleClose}>
              Contact
            </Nav.Link>
          </Nav>

          {/* Call-to-Action Button */}
          <Link to="/contact" onClick={handleClose}>
            <Button variant="warning" className="ms-2">
              Request a Quote
            </Button>
          </Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
